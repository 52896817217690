// src/store/index.js
import { createStore } from 'vuex';
import { authenticateUser } from '@/services/authService';

export default createStore({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('userToken', token); // Сохранение токена в localStorage
    },
    setUser(state, userData) {
      state.user = userData;
      localStorage.setItem('userData', userData); // Сохранение токена в localStorage

    },
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('userToken'); // Удаление токена из localStorage
    }
  },
  actions: {
    initializeAuth({ commit }) {
      const token = localStorage.getItem('userToken');
      if (token) {
        commit('setToken', token);
        // Здесь вы также можете загрузить информацию о пользователе, если это необходимо
      }
    },
    authenticate({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        authenticateUser({ username, password }, (err, response) => {
          if (!err) {
            commit('setToken', response.token);
            commit('setUser', username );
            resolve();
          } else {
            console.error('Authentication error:', err);
            reject(err);
          }
        });
      });
    }
  }
});
