import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Подключаем Vuex store

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: "/protected"
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import('../views/MainPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/protected/card/:id',
    name: 'Card',
    component: () => import('../views/CardPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/protected/addbook/:timedate',
    name: 'AddParams',
    component: () => import('../views/CreateView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/protected/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/protected/mangdates',
    name: 'Dates',
    component: () => import('../views/DatesView.vue'),
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = store.state.token;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
