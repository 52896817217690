<template>
  <RouterView />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  background: #D8EAFF;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border: 1px;
  border-radius: 5px;
  height: 100%;

}
body {
  height: 100%;
}
</style>
